import * as React from "react"
import WithRoot from "../withRoot"

import AWSAmplify, { Auth, Hub } from "aws-amplify"
import awsConfig from "../../aws-exports"

//@ts-ignore
import { withAuthenticator } from "aws-amplify-react"

AWSAmplify.configure(awsConfig)

Hub.listen("auth", data => {
  if (data.payload.event === "signUp") {
  }
})

const IndexPage = () => <div>protected</div>

export default withAuthenticator(WithRoot(IndexPage), { includeGreetings: true })
